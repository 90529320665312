import {
  Container,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  UnorderedList,
  Link,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Link as reactlink } from "react-router-dom";

export default function Footer() {
  return (
    <Stack bg={"#784FCF"} py={6}>
      <Container maxW={{ base: "8xl", xl: "6xl", "2xl": "8xl" }}>
        <SimpleGrid
          columns={{ base: 1, md: 2, xl: 2 }}
          spacing={{ base: 4, md: 10 }}
        >
          <Stack>
            <Text
              fontWeight={600}
              textAlign={{ base: "center", md: "left" }}
              color={"#fff"}
              fontSize={{ base: "13px", md: "15px", xl: "15px", "2xl": "15px" }}
            >
              Copyright 2025{" "}
              <Text
                fontWeight={600}
                color={"rgb(255 176 93)"}
                as={"a"}
                href="#"
                target="_blank"
              >
                Little Hub Daycare Services Inc
              </Text>
              . All Rights Reserved.
            </Text>
          </Stack>
          <Stack justifySelf={{ base: "center", md: "flex-end" }}>
            <UnorderedList listStyleType={"none"} display={"flex"} gap={6}>
              <ListItem>
                <Link
                  fontWeight={600}
                  as={reactlink}
                  color={"#fff"}
                  fontSize={{
                    base: "13px",
                    md: "15px",
                    xl: "15px",
                    "2xl": "15px",
                  }}
                  to={"/TermsServices"}
                >
                  Terms of Service
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  fontWeight={600}
                  as={reactlink}
                  color={"#fff"}
                  fontSize={{
                    base: "13px",
                    md: "15px",
                    xl: "15px",
                    "2xl": "15px",
                  }}
                  to={"/PrivacyPolicy"}
                >
                  Privacy Policy
                </Link>
              </ListItem>
            </UnorderedList>
          </Stack>
        </SimpleGrid>
      </Container>
    </Stack>
  );
}
