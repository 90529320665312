import React, { useEffect, useState } from "react";
import CustHeading from "../../components/Dashboard/Heading/CustHeading";
import Card from "../../components/Dashboard/Card/Card";
import { GET, POST } from "../../utilities/ApiProvider";
import Loader from "../../components/Dashboard/Loader/Loader";
import { useNavigate } from "react-router-dom";

import Pagination from "../../components/Dashboard/Pagination";
import {
  Button,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Box,
  Input,
  Checkbox,
  CheckboxGroup,
  useToast,
  Progress,
} from "@chakra-ui/react";
import { baseURL } from "../../utilities/config";
import { AddIcon } from "@chakra-ui/icons";
import { set } from "lodash";

function DayCares() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pollingInterval, setPollingInterval] = useState(null);
  const [progress, setProgress] = useState(0);
  const toast = useToast();

  const getAllDayCares = async (page) => {
    try {
      const response = await GET(`/daycare/paginate?page=${page}&limit=10`);
      console.log("API Response:", response);
      if (response?.statusCode === 200) {
        // setData([]);
        setData(response?.data?.dayCares || []);
        setTotalPages(response?.data?.pagination?.totalPages || 1); // Updated field path
      } else {
        setData([]);
      }
    } catch (error) {
      console.log("API Error:", error);
    }
  };
  const startPolling = () => {
    const interval = setInterval(async () => {
      const response = await fetch(`${baseURL}/daycare/upload-progress`);
      const data = await response.json();
      console.log(`Progress: ${data.progress}%`);
      setProgress(data.progress);

      if (data.progress === 100) {
        clearInterval(interval);
      }
    }, 1000);
    return interval;
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    getAllDayCares(currentPage);
  }, [currentPage]);
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      if (!file || !selectedService) {
        toast({
          title: "Please select a file and service",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
        return;
      }

      const interval = startPolling();
      setPollingInterval(interval);

      const formData = new FormData();
      formData.append("files", file);
      formData.append("service", selectedService);

      const response = await fetch(`${baseURL}/daycare/new-upload`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        const data = await response.json();
        setLoading(false);
        onClose();
        setProgress(0);
        setFile(null);
        clearInterval(interval);

        toast({
          description: data?.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        return;
      }
      setProgress(100);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selectedService}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setLoading(false);
      onClose();
      setFile(null);
      setProgress(0);
      toast({
        title: "File Downloaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      clearInterval(interval);
    } catch (error) {
      console.log(error);
      setProgress(0);
      setLoading(false);
      toast({
        description: "An error occurred during the file upload.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);

  // const handleSubmit = async (e) => {
  //   setLoading(true);
  //   e.preventDefault();
  //   try {
  //     if (!file || !selectedService) {
  //       toast({
  //         title: "Please select a file and service",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //         position: "top-right",
  //       });
  //       setLoading(false);
  //       return;
  //     }
  //     const formData = new FormData();
  //     formData.append("files", file);
  //     formData.append("service", selectedService);
  //     const response = await fetch(`${baseURL}/daycare/new-upload`, {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       setLoading(false);
  //       onClose();
  //       setFile(null);
  //       toast({
  //         description:
  //           "Failed to upload CSV file.please correct the format and try again",
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //         position: "top-right",
  //       });
  //       return;
  //     }

  //     const blob = await response.blob();
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "processed_data.csv");
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     setLoading(false);
  //     onClose();
  //     setFile(null);
  //     toast({
  //       title: "File Downloaded successfully",
  //       status: "success",
  //       duration: 5000,
  //       isClosable: true,
  //       position: "top-right",
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CustHeading
          name={"All Day Care’s"}
          // subHeading={"Here is the daycare's list....Check Now!"}
        />
        <Stack direction="row">
          <Button
            mr={3}
            bg={"#784FCF"}
            color={"#fff"}
            fontSize={"16px"}
            fontWeight={"500"}
            _hover={{
              bg: "#784FCF",
              color: "#fff",
            }}
            _active={{
              bg: "#784FCF",
              color: "#fff",
            }}
            onClick={() => {
              setProgress(0);
              onOpen();
            }}
            w="157px"
            h="40px"
            borderRadius={"5.5px"}
          >
            Upload CSV File
          </Button>
          <Button
            mr={3}
            bg={"#784FCF"}
            color={"#fff"}
            fontSize={"16px"}
            fontWeight={"500"}
            _hover={{
              bg: "#784FCF",
              color: "#fff",
            }}
            _active={{
              bg: "#784FCF",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/add-new-daycare")}
            w="187px"
            h="40px"
            borderRadius={"5.5px"}
          >
            Add New Day Care
          </Button>
          <Button
            mr={3}
            bg={"#784FCF"}
            color={"#fff"}
            fontSize={"16px"}
            fontWeight={"500"}
            _hover={{
              bg: "#784FCF",
              color: "#fff",
            }}
            _active={{
              bg: "#784FCF",
              color: "#fff",
            }}
            onClick={() => navigate("/dashboard/list-daycares")}
            w="187px"
            h="40px"
            borderRadius={"5.5px"}
          >
            Handle Day Cares
          </Button>
        </Stack>
        <Modal size={"xl"} isCentered isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody py={4} pb={4}>
              <form onSubmit={handleSubmit}>
                <Stack display={"flex"} flexDirection={"column"} gap={3}>
                  <FormControl>
                    <FormLabel
                      fontSize={"16px"}
                      fontWeight={"500"}
                      lineHeight={"30px"}
                    >
                      Upload CSV File
                    </FormLabel>
                    <Box
                      h="56px"
                      borderRadius={"8px"}
                      border={"1px dashed #B4B4B4"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      cursor={"pointer"}
                      onClick={() => document.getElementById("upload").click()}
                    >
                      <Stack
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={3}
                      >
                        <Box
                          w="24px"
                          h="24px"
                          borderRadius={"50%"}
                          border={"1px solid #1A1A1A"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          color="#1A1A1A"
                        >
                          <AddIcon fontSize={"12px"} />
                        </Box>
                        <Box marginTop={"0px !important"}>
                          <Text fontSize={"18px"} fontWeight={"500"}>
                            Upload CSV File
                          </Text>
                        </Box>
                        <Input
                          onChange={handleFileChange}
                          type="file"
                          display="none"
                          id="upload"
                          accept=".csv"
                        />
                        <label htmlFor="upload">
                          <Box
                            as="span"
                            display={"none"}
                            cursor="pointer"
                            padding="10px 20px"
                            borderRadius="8px"
                            border="1px solid #B4B4B4"
                            background="#F3F3F3"
                            _hover={{
                              background: "#E2E2E2",
                            }}
                          >
                            Choose File
                          </Box>
                        </label>
                        {file && <Text marginTop="10px">{file.name}</Text>}
                      </Stack>
                    </Box>
                    <Text
                      color={"red.500"}
                      fontSize={"12px"}
                      my={2}
                      fontWeight={"500"}
                    >
                      *Accepts only .csv file that contain maximum 1000 records
                    </Text>
                  </FormControl>
                  <Stack mt={4}>
                    <CheckboxGroup>
                      <Stack direction={"row"} flexWrap={"wrap"} gap={3}>
                        {[
                          { id: 1, label: "Canada-(ON)" },
                          { id: 2, label: "Alberta" },
                          { id: 3, label: "Newfoundland and Labrador" },
                          { id: 4, label: "Nova Scotia" },
                          { id: 5, label: "British Columbia" },
                          { id: 6, label: "New Brunswik" },
                          { id: 7, label: "Quebec" },
                        ]?.map((item, index) => (
                          <Checkbox
                            key={index}
                            colorScheme="purple"
                            size="lg"
                            checked={true}
                            onChange={() => setSelectedService(item.label)}
                            isChecked={selectedService === item.label}
                          >
                            <Text fontSize={"15px"} fontWeight={"500"}>
                              {item.label}
                            </Text>
                          </Checkbox>
                        ))}
                      </Stack>
                    </CheckboxGroup>
                  </Stack>
                  {progress > 0 && (
                    <Progress value={progress} colorScheme="purple" />
                  )}
                  <Stack py={3}>
                    <Button
                      padding={"10px 50px"}
                      type="submit"
                      bg={"#784FCF"}
                      color={"#fff"}
                      fontSize={"16px"}
                      fontWeight={"500"}
                      _hover={{
                        bg: "#784FCF",
                        color: "#fff",
                      }}
                      _active={{
                        bg: "#784FCF",
                        color: "#fff",
                      }}
                      lineHeight={"inherit"}
                      borderRadius={12}
                      isLoading={loading}
                      h="45px"
                    >
                      {loading ? "Processing..." : "Continue"}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Stack>

      {!data ? (
        <Loader />
      ) : data?.length > 0 ? (
        <>
          <Stack>
            <Card data={data} />
          </Stack>
          <Stack mt={4} direction="row" spacing={4} justifyContent="center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </Stack>
        </>
      ) : (
        <>
          <Text fontSize={"20px"} fontWeight={"500"}>
            No Day Cares Found
          </Text>
        </>
      )}
    </>
  );
}

export default DayCares;
